import React from "react";

export default function ArrowDownIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <mask id="mask0_2653_892" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
         <rect x="0.75" width="16" height="16" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_2653_892)">
          <path d="M8.75004 9.76916L5.81421 6.83333H11.6859L8.75004 9.76916Z" fill="#1C1B1F"/>
        </g>
    </svg>
  );
}
