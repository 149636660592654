// Dependency
import React, { useState } from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import * as Popover from "@radix-ui/react-popover";
import ArrowDownIcon from "../../images/arrowDown";
import Filter from "../../components/filter/filter";
import ArrowLeftIcon from "../../images/arrowLeft";
import ProductCard from "../../components/cards/product-card";
import ProductList from "../product/hooks/product-list";


// Components
import Layout from "../../components/layouts";

// Internationalization
import { useTranslation } from 'react-i18next';

// STYLES
import * as shopStyles from './shop.module.scss'

// GraphQL to get Dato data
export const query = graphql`
  query  ($slug: String!, $locale: String!) {
    centraData: centra {
        products {
          name
          id
          prices {
            price {
              value
              formattedValue
            }
          }
          media {
            source {
              type
              url
            }
          }
          collection {
            name
            id
            uri
          }
        }
      }
    allDatoCmsCategoryPage(
      filter: {seoSlug: { eq: $slug },locale: {eq: $locale}}
    ){
    nodes {
        locale
        seoSlug
        title
        description
      }
    }
    categories: allDatoCmsCategoryPage(
        filter: {locale: {eq: $locale}}
      ) {
        nodes {
            title
            seoSlug
            locale
        }
      }
    products: allDatoCmsProduct(
        sort: { fields: position, order: ASC }
        filter: {locale: {eq: $locale}, productCategory: {elemMatch: {slug: {eq: $slug }}}}
      ) {
        nodes {
          centraId
          id
          title
          productType
          parentProductName
          slug
          featuredImage {
             fluid(maxWidth: 700) {
                ...GatsbyDatoCmsFluid
              }
          }
          locale
          trustpilotId
          saleBadge
          bestseller
          newPrice
        }
      }
}
`


// Template
const Compare = (props) => {
  const pageData = props.data.allDatoCmsCategoryPage.nodes;
  // Recovery category products
  const products = props.data.products.nodes;

  const categories = props.data.categories.nodes;

  const [productList, setProductList] = useState([]);

  // Translation Function
  const { t } = useTranslation();


  return (

    <Layout country={props.pageContext.country} location={props.location}>
      <ProductList createProductList={setProductList} isCampaign={props.location.pathname === `/${props.pageContext.country}/shop/member/`} country={props.pageContext.country} />

      <div className={shopStyles.cdShopFilter}>
        <div className="cd-max-width cd-large-width">
          <div className={shopStyles.cdShopDesc}>
            <div className={shopStyles.cdShopTitle}>
              <Link to={`/${props.pageContext.country}/shop`}>
                <ArrowLeftIcon />
              </Link>
              <h1>{pageData[0].title}</h1>
            </div>
            {pageData[0].description &&
              <p>
                {pageData[0].description}
              </p>
            }
          </div>
          <div className={shopStyles.cdShopCats}>
            {categories && categories.map((element, index) => {
              return (
                <Link
                  className={
                    // if slug is the same as the current page, add active class
                    element.seoSlug === pageData[0].seoSlug ? shopStyles.cdShopCatActive : ""
                  }
                  to={`/${props.pageContext.country}/${element.seoSlug}`} key={index}>{element.title}</Link>
              )
            })}
          </div>
          <div className={shopStyles.cdShopFilterWrapper}>
            {/* <Filter categories={categories} lang={props.pageContext.country} /> */}
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="cd-shop-select cd-shop-select-cats" aria-label="Category">
                  Category
                  <ArrowDownIcon />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className="PopoverContent cd-shop-select-content" sideOffset={5} align="start">
                  {categories && categories.map((element, index) => {
                    return (
                      <Popover.Close className="cd-filter-modal-category" key={index}>
                        <Link to={`/${props.pageContext.country}/${element.seoSlug}`} >{element.title}</Link>
                      </Popover.Close>
                    )
                  })}
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="cd-shop-select" aria-label="Sort">
                  Recommended
                  <ArrowDownIcon />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className="PopoverContent cd-shop-select-content" sideOffset={5} align="end">
                  <Popover.Close className="cd-filter-modal-category">
                    Recommended
                  </Popover.Close>
                  <Popover.Close className="cd-filter-modal-category">
                    New
                  </Popover.Close>
                  <Popover.Close className="cd-filter-modal-category">
                    Highest price
                  </Popover.Close>
                  <Popover.Close className="cd-filter-modal-category">
                    Lowest price
                  </Popover.Close>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        </div>
      </div>
      <div className={shopStyles.cdShopWrapper}>
        <section className={shopStyles.cdShopCatSection} id={""}>
          <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {
              products && products.map((element, index) => {
                //Get mapped Centra Prod
                let prodFilter = productList.filter((prod) => {
                  return element.centraId.toString() === prod.centraProduct;
                })

                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard centraProd={centraProd} key={element.id} position={index + 1} data={element} placement="Product List" />
                  </div>
                )
              }
              )}
          </div>
        </section>
      </div>
    </Layout>
  )
}


export default Compare;